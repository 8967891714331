import type { FC } from 'react';

import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Input, Textarea } from '@saturn/uikit';

import type { ContentBlockProps } from 'shared/components';
import { MarkdownTip } from 'shared/components';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import styles from './WhatIsIncludedCard.module.scss';

export const WhatIsIncludedCard: FC<ContentBlockProps> = ({ name, required, ...fieldProps }) => {
  return (
    <div className={styles.wrapper}>
      <Form.Item
        {...fieldProps}
        name={[name, 'cardImage']}
        rules={[
          {
            required,
            message: 'Image is required',
          },
        ]}
      >
        <ImageUploader
          content="Upload Image"
          tooltip="Recommended ratio is 1:1"
          className={styles.image_container}
          valueFormatter={fileUrlFormatter}
          action={uploadFileUrl(`whatIsIncluded_card_image_${name}`)}
          customRequest={onCustomRequest}
          maxSize={100}
          style={{
            width: 265,
            height: 225,
          }}
        />
      </Form.Item>
      <div className={styles.inputsWrapper}>
        <Form.Item
          {...fieldProps}
          name={[name, 'title']}
          rules={[
            {
              required,
              message: 'Title is required',
              whitespace: true,
            },
            ...(required
              ? [
                  {
                    max: 255,
                    message: 'Title should be at most 255 characters',
                  },
                ]
              : []),
          ]}
        >
          <Input label="Card Title" />
        </Form.Item>
        <MarkdownTip>
          <Form.Item
            {...fieldProps}
            name={[name, 'description']}
            rules={[
              {
                required,
                message: 'Description is required',
                whitespace: true,
              },
              ...(required
                ? [
                    {
                      max: 10000,
                      message: 'Description should be at most 10000 characters',
                    },
                  ]
                : []),
            ]}
          >
            <Textarea label="Card Description" />
          </Form.Item>
        </MarkdownTip>
        <Form.Item
          {...fieldProps}
          name={[name, 'seoAltTag']}
          rules={[
            ...(required
              ? [
                  {
                    max: 255,
                    message: 'Alt Tag for SEO should be at most 255 characters',
                  },
                ]
              : []),
          ]}
        >
          <Input label="Alt Tag for SEO" />
        </Form.Item>
      </div>
    </div>
  );
};
