import { ComponentPropsWithoutRef, Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-scroll';

import cn from 'classnames';
import type { XYCoord } from 'dnd-core';
import update from 'immutability-helper';

import { Icon } from '@saturn/uikit';

import { useAdminLocation } from '../../utils';
import { NestedSidebarList, getIndices } from './NestedSidebarItem';
import { DefaultIndexValue, IndicesType } from './types';

import styles from './SidebarList.module.scss';

interface CardProps extends ComponentPropsWithoutRef<'div'> {
  id: string;
  children: ReactNode;
  index: number;
  setIndices?: Dispatch<SetStateAction<IndicesType>>;
  disabled?: boolean;
  sidebarListValues: Record<string, boolean | null>;
  parent: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
}

function Card({ id, disabled = false, children, index, moveCard, ...props }: CardProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem, void>({
    accept: 'CARD',
    hover: (item: DragItem, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Get pixels to the top
      const hoverClientY = (monitor.getClientOffset() as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: 'CARD',
    canDrag: () => !disabled,
    item: () => ({ id, index }),
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} {...props} className={cn(props.className, styles.card, { [styles.draggableCard]: !disabled })}>
      {children}
    </div>
  );
}

type SidebarListProps = {
  parent: string;
  sidebarListValues: Record<string, boolean | null>;
  sectionTitles: Record<string, string>;
  indices?: IndicesType;
  defaultIndices?: Record<string, DefaultIndexValue>;
  setIndices?: Dispatch<SetStateAction<IndicesType>>;
};

export function SidebarList({
  parent,
  sidebarListValues,
  sectionTitles,
  indices,
  defaultIndices,
  setIndices,
}: SidebarListProps) {
  const { locationLanguage } = useAdminLocation();

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    setIndices?.(prevState => {
      return getIndices(
        update(prevState, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevState[dragIndex]],
          ],
        }),
        sidebarListValues,
      );
    });
  };

  useEffect(() => {
    if (defaultIndices && setIndices && indices?.length === 0) {
      const indices = Object.entries(defaultIndices)
        .sort((a, b) => {
          return a[1].order < b[1].order ? -1 : 0;
        })
        .map(([defaultIndKey, defaultIndKeyValue]) => {
          let sub = null;
          if (defaultIndKeyValue.subSections) {
            sub = Object.entries(defaultIndKeyValue.subSections)
              .toSorted((a, b) => {
                // @ts-ignore
                return a[1].order < b[1].order ? -1 : 0;
              })
              .map(([subSectionKey, subSectionValue]) => {
                return [
                  subSectionKey,
                  {
                    // @ts-ignore
                    order: subSectionValue.order,
                    subSections: null,
                  },
                ];
              });
          }
          return [defaultIndKey, { order: defaultIndKeyValue.order, subSections: sub }];
        })
        .filter(([x]) => sidebarListValues[x as string] != null);
      setIndices(getIndices(indices as IndicesType, sidebarListValues));
    }
  }, [defaultIndices, sidebarListValues, setIndices, indices]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.wrapper}>
        {indices &&
          indices.map(([key, item], i) => {
            const nestedSidebarListValues: Record<string, boolean | null> =
              item?.subSections?.reduce((acc, section) => {
                const sectionKey = section[0];
                return {
                  ...acc,
                  [sectionKey]: sidebarListValues[sectionKey] ?? false,
                };
              }, {}) ?? {};
            return (
              <Card
                id={key}
                disabled={
                  !setIndices ||
                  ['planLegalDisclaimer', 'highlightPlan', 'promotionBenefit', 'referFriend', 'nextSteps'].includes(key)
                }
                index={i}
                key={key}
                setIndices={setIndices}
                style={{
                  order: indices.findIndex(el => {
                    return el[0] === key;
                  }),
                }}
                sidebarListValues={sidebarListValues}
                parent={parent}
                moveCard={moveCard}
              >
                <div
                  className={cn('saturn-sidebar-product', styles.sidebarListItem, {
                    [styles.notVisible]: sidebarListValues[key] === false,
                    [styles.parentItem]: item.subSections?.length,
                  })}
                >
                  <Link
                    activeClass="active"
                    className="saturn-scroll-link"
                    to={`${key}_${locationLanguage}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    {sectionTitles[key]}
                  </Link>
                  {sidebarListValues[key] !== null && (
                    <Icon
                      name={sidebarListValues[key] ? 'eye-outline' : 'eye-off-outline'}
                      size={18}
                      color={'#aab2ba'}
                    />
                  )}
                </div>
                {item.subSections && defaultIndices && defaultIndices[key] && (
                  <NestedSidebarList
                    parent={`product_${key}`}
                    sidebarListValues={nestedSidebarListValues}
                    sectionTitles={sectionTitles}
                    indices={item.subSections}
                    setIndices={newIndicies => {
                      const modifiedIndices = [...indices];
                      const indexToModify = modifiedIndices.findIndex(ind => ind[0] === key);
                      modifiedIndices[indexToModify] = [key, { order: item.order, subSections: newIndicies }];
                      if (setIndices) {
                        setIndices(modifiedIndices);
                      }
                    }}
                  />
                )}
              </Card>
            );
          })}
      </div>
    </DndProvider>
  );
}
