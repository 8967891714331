import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';

import { Upload as AntUpload, notification } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import cn from 'classnames';
import noop from 'lodash/noop';
import uniq from 'lodash/uniq';

import { getAccessToken } from '@saturn/api';

import { DefaultFileForUploader, FileUploaderProps } from './models';

import styles from './FileUploader.module.scss';

const FILE_MIME_TYPES: Record<string, string> = {
  pdf: 'application/pdf',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
};

export const FileUploader: FC<FileUploaderProps> = props => {
  const {
    style,
    className,
    children,
    value,
    valueFormatter,
    isSecure = false,
    filesTypes,
    maxSize,
    isDelayedUpload,
    addToFileList,
    onChange = noop,
    fileList,
    ...rest
  } = props;

  const [defaultFileList, setDefaultFileList] = useState<DefaultFileForUploader[] | undefined>(() => {
    return valueFormatter(value);
  });

  useEffect(() => {
    const files = valueFormatter(value);
    if (files) {
      setDefaultFileList(files);
    }
  }, [value, valueFormatter]);

  const handleChange = useCallback(
    (info: UploadChangeParam) => {
      let infoFormItem;
      const { status, response, name } = info?.file ?? {};
      if (status === 'done' && response?.id) {
        infoFormItem = { id: response.id, title: name };
      } else {
        infoFormItem = null;
      }
      onChange(infoFormItem);
    },
    [onChange],
  );

  const beforeUpload = (file: RcFile) => {
    if (maxSize && file.size > maxSize) {
      notification.error({
        message: 'File has not been uploaded',
        description: `The file ${file.name} could not be uploaded because it exceeds ${(
          maxSize / 1000000
        ).toFixed()}MB, the maximum allowed size for uploads`,
      });
      return AntUpload.LIST_IGNORE;
    }
    if (filesTypes && filesTypes.length > 0) {
      const mimeTypes = uniq(filesTypes.map(fT => FILE_MIME_TYPES[fT]).filter(fT => fT));
      if (!mimeTypes.includes(file.type)) {
        notification.error({
          message: 'File has not been uploaded',
          description: `Only files with these extensions are allowed: ${filesTypes.join(', ')}.`,
        });
        return AntUpload.LIST_IGNORE;
      }
    }
    if (isDelayedUpload) {
      addToFileList && addToFileList(file);
      return false;
    } else {
      return true;
    }
  };

  return (
    <div style={style} className={cn(className, 'saturn-file-uploader', styles.wrapper)}>
      <AntUpload
        beforeUpload={beforeUpload}
        maxCount={1}
        onChange={handleChange}
        headers={isSecure ? { authorization: getAccessToken() } : undefined}
        defaultFileList={defaultFileList}
        fileList={fileList}
        {...rest}
      >
        {children}
      </AntUpload>
    </div>
  );
};
