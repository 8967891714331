import type { FC } from 'react';

import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, Tip, VisibilityChangeListener } from 'shared/components';
import { MarkdownTip } from 'shared/components/MarkdownTip';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import styles from './CallUsBack.module.scss';

export type CallUsBackProps = {
  header?: string;
};

export const CallUsBack: FC<CallUsBackProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Fast Insurance'} name={['callUsBack']}>
      <div className={styles.content}>
        <VisibilityChangeListener section="callUsBack">
          {required => (
            <div className={styles.wrapper}>
              <Form.Item
                name={['callUsBack', 'fileMetadata']}
                rules={[
                  {
                    required,
                    message: 'Picture is required',
                  },
                ]}
              >
                <ImageUploader
                  content="Upload Picture"
                  tooltip="Recommended dimensions are 540*405px"
                  className={styles.image_container}
                  action={uploadFileUrl('banner_image')}
                  customRequest={onCustomRequest}
                  valueFormatter={fileUrlFormatter}
                  style={{
                    width: 265,
                    height: 225,
                  }}
                />
              </Form.Item>
              <div className={styles.inputsWrapper}>
                <Form.Item
                  name={['callUsBack', 'sectionTitle']}
                  rules={[
                    {
                      required,
                      message: 'Title is required',
                      whitespace: true,
                    },
                    ...(required
                      ? [
                          {
                            max: 50,
                            message: 'Title should be at most 50 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <Input label="Title" />
                </Form.Item>
                <div className={styles.textArea_wrapper}>
                  <Form.Item
                    className={styles.formItem}
                    name={['callUsBack', 'sectionDescription']}
                    rules={[
                      {
                        required,
                        message: 'Description is required',
                        whitespace: true,
                      },
                      ...(required
                        ? [
                            {
                              max: 250,
                              message: 'Description should be at most 250 characters',
                            },
                          ]
                        : []),
                    ]}
                  >
                    <Textarea label="Description" />
                  </Form.Item>
                </div>
                <MarkdownTip />
                <Form.Item
                  name={['callUsBack', 'mobileNumber']}
                  rules={[
                    {
                      required,
                      message: 'Mobile Number is required',
                    },
                    ...(required
                      ? [
                          {
                            min: 3,
                            message: 'Should be at least 3 characters',
                          },
                          {
                            max: 30,
                            message: 'Should be at most 30 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <Input label="Mobile Number" />
                </Form.Item>
                <Tip>
                  Due to variations in mobile number formats across different locations, the &quot;call by click&quot;
                  feature may not be supported in certain edge cases. If issues arise with calling by click, we suggest
                  changing the format of the mobile number. For example, instead of &quot;93 922 - 22- 223&quot;, you
                  could try &quot;+93 (922) 22 223&quot; and so on.
                </Tip>
              </div>
            </div>
          )}
        </VisibilityChangeListener>
      </div>
    </CollapsiblePanel>
  );
};
