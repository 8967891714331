import type { FC } from 'react';

import { Alert } from 'antd';

import { uploadFileUrl } from '@saturn/api';
import { Button, FileUploader, Form, Icon, Input, RadioGroup, RuleType, Textarea } from '@saturn/uikit';

import { ContentBlockProps } from 'shared/components';
import { DOCUMENT_TYPE } from 'shared/constants';
import { onCustomRequest } from 'shared/services/api';
import { fileListFormatter } from 'shared/utils';

import styles from '../../Documents.module.scss';

const docTypeOptions = Object.values(DOCUMENT_TYPE).map(value => ({ value, label: value }));
export const DocumentsBlock: FC<ContentBlockProps> = ({ name, parentName = [], required, ...fieldProps }) => {
  return (
    <Form.Item required={false} noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const isLink = getFieldValue([...parentName, name, 'documentType']) === DOCUMENT_TYPE.link;
        return (
          <div className={styles.inputsWrapper}>
            <Form.Item
              {...fieldProps}
              name={[name, 'documentType']}
              initialValue={DOCUMENT_TYPE.link}
              rules={[
                {
                  required,
                  message: 'Document Type is required',
                },
              ]}
            >
              <RadioGroup options={docTypeOptions} />
            </Form.Item>
            <Form.Item
              {...fieldProps}
              name={[name, 'title']}
              rules={[
                {
                  required,
                  message: 'Document Title is required',
                  whitespace: true,
                },
                ...(required
                  ? [
                      {
                        max: 255,
                        message: 'Document Title should be at most 255 characters',
                      },
                    ]
                  : []),
              ]}
            >
              <Input label="Document Title" />
            </Form.Item>
            <Form.Item
              {...fieldProps}
              name={[name, 'planName']}
              rules={[
                {
                  max: 100,
                  message: 'Plan Name should be at most 100 characters',
                },
              ]}
            >
              <Input label="Plan Name" />
            </Form.Item>
            <Alert
              showIcon
              icon={<Icon color="#f48809" name="alert-circle-outline" />}
              type="warning"
              message="If a value is empty or the value does not match with title of any plan - the document displayed for any
              plan selected. If specified value match with a plan name - the document is displayed only if matching plan
              is selected."
            />
            {isLink ? (
              <Form.Item
                {...fieldProps}
                preserve={false}
                name={[name, 'link']}
                rules={
                  isLink
                    ? [
                        {
                          required,
                          message: 'Link is required',
                        },
                        ...(required ? [{ type: 'url' as RuleType, message: 'Link is not valid' }] : []),
                      ]
                    : []
                }
              >
                <Input label="Add Link" />
              </Form.Item>
            ) : (
              <Form.Item
                {...fieldProps}
                preserve={false}
                name={[name, 'fileMetadata']}
                rules={[
                  {
                    required,
                    message: 'File is required',
                  },
                ]}
              >
                <FileUploader
                  isSecure
                  filesTypes={['pdf']}
                  action={(file: File) => Promise.resolve(uploadFileUrl(file.name))}
                  customRequest={onCustomRequest}
                  valueFormatter={!isLink ? fileListFormatter : () => undefined}
                >
                  <div className={styles.buttonTitle}>Document File</div>
                  <Button className="button_file-uploader">Choose a file...</Button>
                </FileUploader>
              </Form.Item>
            )}
            <Form.Item
              {...fieldProps}
              name={[name, 'description']}
              rules={[
                ...(required
                  ? [
                      {
                        min: 3,
                        message: 'Document Description should be at least 3 characters',
                      },
                      {
                        max: 255,
                        message: 'Document Description should be at most 255 characters',
                      },
                    ]
                  : []),
              ]}
            >
              <Textarea label="Document Description" />
            </Form.Item>
          </div>
        );
      }}
    </Form.Item>
  );
};
