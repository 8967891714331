import type { FC } from 'react';

import { Form, Switch, SwitchChangeEventHandler } from '@saturn/uikit';

import styles from './SectionVisibilitySwitch.module.scss';

interface SectionVisibilitySwitchProps {
  onChange?: SwitchChangeEventHandler;
  switchName?: string | string[];
  defaultChecked?: boolean;
}

export const SectionVisibilitySwitch: FC<SectionVisibilitySwitchProps> = ({ onChange, switchName, defaultChecked }) => {
  return (
    <span className={styles.switch}>
      <span className={styles.switchText}>
        {switchName === 'enabled' || switchName?.at(-1) === 'enabled' ? 'Custom' : 'Visible section'}
      </span>
      <Form.Item className={styles.toggler} name={switchName} valuePropName="checked" initialValue={defaultChecked}>
        <Switch onChange={onChange} />
      </Form.Item>
    </span>
  );
};
