import { useMemo, useState } from 'react';
import type { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import { notification } from '@saturn/uikit';

import { LoginLayout, PageHeader } from 'shared/components';
import { addTokenToRequest } from 'shared/services/api';
import { useAdminLocation, useAuth } from 'shared/utils';

import { ConfirmEmailForm, LoginForm } from 'features/login/components';
import { ConfirmEmailFormInterface, LoginFormInterface } from 'features/login/interfaces';

export const LoginPage: FC = () => {
  const { isAuthEnabled, isAuthorized, refreshUser } = useAuth();
  const { adminLocation } = useAdminLocation();

  const key = 'Check email for auth code';
  const [screen, setScreen] = useState<number>(0);
  const [userEmail, setUserEmail] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');

  const { run: generateTokens } = useRequest(admin.checkOtp, {
    manual: true,
    onSuccess: tokensData => {
      if (tokensData.accessToken) {
        localStorage.setItem('accessToken', tokensData.accessToken);
        addTokenToRequest();
        refreshUser();
        notification.destroy(key);
      }
    },
  });

  const { run: sendOtpPassword } = useRequest(admin.sendOtp, {
    manual: true,
    onSuccess: sessionData => {
      setScreen(1);
      setSessionId(sessionData.sessionId);
      notification.success({
        key,
        duration: 10,
        message: `Please check your email and enter the code we sent to ${userEmail}`,
      });
    },
  });

  const onLoginFormFinish = (values: LoginFormInterface) => {
    setUserEmail(values.email);
    sendOtpPassword(values);
  };

  const onConfirmEmailFormFinish = (values: ConfirmEmailFormInterface) => {
    generateTokens({ ...values, sessionId });
  };

  const onPrevScreen = () => setScreen(0);

  const onSendCodeAgain = () => {
    sendOtpPassword({ email: userEmail });
  };

  const currentScreen = useMemo(() => {
    switch (screen) {
      case 0:
        return <LoginForm onLoginFormFinish={onLoginFormFinish} />;
      case 1:
        return (
          <ConfirmEmailForm
            onSendCodeAgain={onSendCodeAgain}
            onPrevScreen={onPrevScreen}
            onConfirmEmailFormFinish={onConfirmEmailFormFinish}
          />
        );
      default:
        return null;
    }
  }, [screen, sessionId]);

  if (!isAuthEnabled || isAuthorized) {
    return <Navigate to={`/${adminLocation?.id}`} replace />;
  }

  return (
    <LoginLayout>
      <main>
        <PageHeader title="Login" subTitle="Login to admin panel" />
        <div>{currentScreen}</div>
      </main>
    </LoginLayout>
  );
};
