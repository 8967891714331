import type { FC } from 'react';

import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Input, Textarea } from '@saturn/uikit';

import { ContentBlockProps, MarkdownTip } from 'shared/components';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import { urlValidator } from '../../logic';

import styles from '../../FindOutMore.module.scss';

export const FindOutMoreBlock: FC<ContentBlockProps> = ({ name, required, ...fieldProps }) => {
  return (
    <div className={styles.wrapper}>
      <Form.Item {...fieldProps} name={[name, 'fileMetadata']}>
        <ImageUploader
          content="Upload Image"
          tooltip="Recommended dimensions are 950*580px"
          className={styles.image_container}
          valueFormatter={fileUrlFormatter}
          action={uploadFileUrl(`find-out-more_image_${name}`)}
          customRequest={onCustomRequest}
          style={{
            width: 265,
            height: 225,
          }}
        />
      </Form.Item>
      <div className={styles.inputsWrapper}>
        <Form.Item
          {...fieldProps}
          name={[name, 'pageUrl']}
          rules={[
            ...(required
              ? [
                  {
                    validator: urlValidator,
                  },
                ]
              : []),
          ]}
        >
          <Input label="Link (optional)" />
        </Form.Item>
        <Form.Item
          {...fieldProps}
          name={[name, 'name']}
          rules={[
            {
              required,
              message: 'Title is required',
              whitespace: true,
            },
            ...(required
              ? [
                  {
                    max: 100,
                    message: 'Title should be at most 100 characters',
                  },
                ]
              : []),
          ]}
        >
          <Input label="Title" />
        </Form.Item>

        <MarkdownTip>
          <Form.Item
            {...fieldProps}
            name={[name, 'description']}
            rules={[
              {
                required,
                message: 'Description is required',
                whitespace: true,
              },
              ...(required
                ? [
                    {
                      max: 3000,
                      message: 'Description should be at most 3000 characters',
                    },
                  ]
                : []),
            ]}
          >
            <Textarea label="Description" />
          </Form.Item>
        </MarkdownTip>
        <Form.Item
          {...fieldProps}
          name={[name, 'seoAltTag']}
          rules={[
            ...(required
              ? [
                  {
                    max: 255,
                    message: 'Alt Tag for SEO should be at most 255 characters',
                  },
                ]
              : []),
          ]}
        >
          <Input label="Alt Tag for SEO" />
        </Form.Item>
      </div>
    </div>
  );
};
