const PRODUCT_SECTION_TITLES: Record<string, string> = {
  seo: 'SEO',
  info: 'General Info',
  planLegalDisclaimer: 'Legal Disclaimer',
  referFriend: 'Refer a friend',
  callUsBack: 'Call Us Back',
  hero: 'Hero Banner',
  video: 'Video',
  feature: 'Features',
  about: 'About',
  callbackWidgetSection: 'Callback Widget',
  promoEventBanner: 'Promo Event Banner',
  allYouNeedToKnow: 'All You Need to Know',
  whatIsCovered: 'What is Covered',
  whoIsCovered: 'Who is Covered',
  coverage: 'Coverages',
  faq: 'FAQs',
  document: 'Documents',
  contact: 'Still Have Questions?',
  insurancePartners: 'Insurance Partners',
  whyAmericanExpress: 'Why American Express',
  disclosuresFooter: 'Footer Disclosures',
  secondaryFooterDisclosure: 'Non-amex Footer Disclosures',
  nextSteps: 'Next Steps',
  breadcrumb: 'Breadcrumb',
  promotionBenefit: 'Promotion Benefits',
  highlightPlan: 'Most popular plan',
  categoryProducts: 'Other Products',
  whatIsIncludedSection: 'What Is Included',
  overview: 'Product Overview',
};

export const draggableSections = [
  'promoEventBanner',
  'hero',
  'callUsBack',
  'video',
  'feature',
  'about',
  'categoryProducts',
  'callbackWidgetSection',
  'promotionBenefit',
  'allYouNeedToKnow',
  'whatIsCovered',
  'whoIsCovered',
  'coverage',
  'faq',
  'document',
  'contact',
  'disclosuresFooter',
  'whatIsIncludedSection',
  'overview',
];

export default PRODUCT_SECTION_TITLES;
