import { ProductDto } from '@saturn/api';

import { About, FooterDisclosures, HeroBanner, LanguageTabs, PageSEO } from 'shared/components';
import type { ProviderOption } from 'shared/utils';

import { HowTo } from '../HowTo/HowTo';
import { InsuranceProducts } from '../InsuranceProducts/InsuranceProducts';
import { Stripe } from '../Stripe/Stripe';

type PromoEventTabsProps = {
  products: ProductDto[];
  selectedProducts?: string[];
  isShowLeaveModal: boolean;
  sectionTitles: Record<string, string>;
  providers: ProviderOption[];
};

export function PromoEventTabs({
  products = [],
  selectedProducts = [],
  isShowLeaveModal,
  sectionTitles,
  providers,
}: PromoEventTabsProps): JSX.Element {
  return (
    <>
      <LanguageTabs isShowLeaveModal={isShowLeaveModal}>
        <PageSEO header={sectionTitles.seo} />
        <HeroBanner header={sectionTitles.hero} providers={providers} />
        <InsuranceProducts
          header={sectionTitles.categoryProducts}
          selectedInsuranceProducts={selectedProducts}
          products={products}
        />
        <HowTo header={sectionTitles.howToSection} />
        <About header={sectionTitles.about} />
        <Stripe header={sectionTitles.stripe} />
        <FooterDisclosures />
      </LanguageTabs>
    </>
  );
}
