import type { AxiosRequestHeaders } from 'axios';

import axiosClient, { AxiosError, AxiosResponse, admin, getAccessToken } from '@saturn/api';
import { notification } from '@saturn/uikit';

import { addTokenToRequest } from './addTokenToRequest';

let flag: Promise<boolean> | null = null;

async function refreshToken(): Promise<boolean> {
  try {
    const res = await admin.refreshToken();
    if (res.status === 200) {
      localStorage.setItem('accessToken', res.data.accessToken);
      addTokenToRequest();
      flag = null;
      return true;
    }
    flag = null;
    return false;
  } catch (e) {
    notification.error({
      message: 'Session is expired, sign in again',
    });
    localStorage.removeItem('accessToken');
    addTokenToRequest();
    window.location.reload();
    flag = null;
    return false;
  }
}

export async function refreshAndRetry<T>({ config }: AxiosError<T>): Promise<AxiosResponse<T>> {
  if (flag === null) {
    flag = refreshToken();
  }
  await flag;
  if (config) {
    if (config.headers) {
      config.headers.Authorization = getAccessToken();
    } else {
      config.headers = { Authorization: getAccessToken() } as AxiosRequestHeaders;
    }
    return axiosClient(config);
  }
  return Promise.reject();
}
