import type { FC } from 'react';

import cn from 'classnames';

import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Input, Option, Select, Textarea } from '@saturn/uikit';

import type { ContentBlockProps } from 'shared/components';
import { MarkdownTip } from 'shared/components';
import { TEXT_BLOCK_SELECT } from 'shared/constants';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import styles from '../../AboutBlock.module.scss';

export const AboutBlock: FC<ContentBlockProps> = ({ name, parentName = [], required, ...fieldProps }) => {
  return (
    <Form.Item required={false} noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const currentLayout = getFieldValue([...parentName, name, 'layout']);
        return (
          <div
            className={cn(styles.wrapper, {
              [styles['single']]: currentLayout === TEXT_BLOCK_SELECT.textOnly.value,
            })}
          >
            {currentLayout !== TEXT_BLOCK_SELECT.textOnly.value && (
              <Form.Item
                {...fieldProps}
                name={[name, 'fileMetadata']}
                rules={[
                  {
                    required,
                    message: 'Image is required',
                  },
                ]}
              >
                <ImageUploader
                  content="Upload Image"
                  tooltip="Recommended dimensions are 950*580px"
                  className={styles.image_container}
                  valueFormatter={fileUrlFormatter}
                  action={uploadFileUrl(`about_image_${name}`)}
                  customRequest={onCustomRequest}
                  style={{
                    width: 265,
                    height: 225,
                  }}
                />
              </Form.Item>
            )}
            <div className={styles.inputsWrapper}>
              <Form.Item
                {...fieldProps}
                name={[name, 'layout']}
                initialValue={TEXT_BLOCK_SELECT.imageLeftTextRight.value}
                rules={[
                  {
                    required,
                    message: 'Content Layout is required',
                  },
                ]}
              >
                <Select placeholder="Content Layout" defaultValue={TEXT_BLOCK_SELECT.imageLeftTextRight.value}>
                  {Object.values(TEXT_BLOCK_SELECT).map(({ text, value }) => (
                    <Option key={value} data-key={value} value={value}>
                      {text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...fieldProps}
                name={[name, 'title']}
                rules={[
                  {
                    required,
                    message: 'Text Block Title is required',
                    whitespace: true,
                  },
                  ...(required
                    ? [
                        {
                          max: 255,
                          message: 'Text Block Title should be at most 255 characters',
                        },
                      ]
                    : []),
                ]}
              >
                <Input label="Text Block Title" />
              </Form.Item>
              <MarkdownTip>
                <Form.Item
                  {...fieldProps}
                  name={[name, 'description']}
                  rules={[
                    {
                      required,
                      message: 'Description is required',
                      whitespace: true,
                    },
                    ...(required
                      ? [
                          {
                            max: 1000,
                            message: 'Description should be at most 1000 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <Textarea label="Description" />
                </Form.Item>
              </MarkdownTip>
              {currentLayout !== TEXT_BLOCK_SELECT.textOnly.value && (
                <Form.Item
                  {...fieldProps}
                  name={[name, 'seoAltTag']}
                  rules={[
                    ...(required
                      ? [
                          {
                            max: 255,
                            message: 'Alt Tag for SEO should be at most 255 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <Input label="Alt Tag for SEO" />
                </Form.Item>
              )}
            </div>
          </div>
        );
      }}
    </Form.Item>
  );
};
