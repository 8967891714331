import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';
import { validationRules } from './constants';

import styles from './ProductOverview.module.scss';

type ProductOverviewProps = {
  header?: string;
};

const SECTION_NAME = 'overview';

export const ProductOverview = ({ header }: ProductOverviewProps) => {
  return (
    <CollapsiblePanel header={header || 'Product Overview'} name={[SECTION_NAME]}>
      <div className={styles.wrapper}>
        <Form.Item name={[SECTION_NAME, 'fileMetadata']}>
          <ImageUploader
            content="Upload Picture"
            tooltip="Recommended aspect ratio 3:2"
            action={uploadFileUrl('banner_image')}
            customRequest={onCustomRequest}
            valueFormatter={fileUrlFormatter}
            style={{
              width: 265,
              height: 225,
            }}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const required = getFieldValue([SECTION_NAME, 'visible']);
            return (
              <>
                <Form.Item name={[SECTION_NAME, 'sectionTitle']} rules={required ? validationRules.title : []}>
                  <Input label="Section Title" />
                </Form.Item>
                <Form.Item
                  name={[SECTION_NAME, 'sectionDecsription']}
                  rules={required ? validationRules.description : []}
                >
                  <Textarea label="Description" />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </div>
    </CollapsiblePanel>
  );
};
